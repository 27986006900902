
import Vue from 'vue'

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
Vue.use(ViewUI);

Vue.prototype.$Message.config({
    top: 400,
    duration: 2
    // duration: 5
});

// 使用 vue2-datepicker 覆盖 iView(view-desgin) 的 DatePicker
// 可能是解决 iview v-model 的双向绑定问题
// 该日期插件要显示中文 要引入对于 中文包
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
Vue.component('DatePicker', DatePicker)

import areaTree from '@/components/base/areaTree/areaTree.vue'
Vue.component('areaTree', areaTree)

import customTable from '@/components/base/customTable/customTable.vue'
Vue.component('customTable', customTable)

import loading from '@/components/base/loading/loading.vue'
import maskLayer from '@/components/base/maskLayer/maskLayer.vue'
Vue.component('loading', loading)
Vue.component('maskLayer', maskLayer)

import iconModal from '@/components/base/iconModal/iconModal.vue'
Vue.component('iconModal', iconModal)

import leftTabs from '@/components/base/leftTabs/leftTabs.vue'
import pane from '@/components/base/leftTabs/pane.vue'
Vue.component('leftTabs', leftTabs)
Vue.component('pane', pane)

import vDistpicker from '@/components/base/vDistpicker/vDistpicker.vue'
Vue.component('vDistpicker', vDistpicker)

import tDistpicker from '@/components/base/tDistpicker/tDistpicker.vue'
Vue.component('tDistpicker', tDistpicker)

import layuiTree from '@/components/base/layuiTree/layuiTree.vue'
Vue.component('layuiTree', layuiTree)

import countNum from '@/components/base/countNum/countNum.vue'
Vue.component('countNum', countNum)

import countTime from '@/components/base/countTime/countTime.vue'
Vue.component('countTime', countTime)

