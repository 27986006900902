import Vue from 'vue'
import VueRouter from 'vue-router'

// import base from '@/views/base.js'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/login',
    component: () =>
      import('@/views/main/login/login.vue' /* webpackChunkName: "login" */),
  },
  {
    path: '/register',
    component: () =>
      import(
        '@/views/main/register/register.vue' /* webpackChunkName: "register" */
      ),
  },
  {
    path: '/forgetpass',
    component: () =>
      import(
        '@/views/main/forgetpass/forgetpass.vue' /* webpackChunkName: "forgetpass" */
      ),
  },
  {
    path: '/modifypass',
    component: () =>
      import(
        '@/views/main/modifypass/modifypass.vue' /* webpackChunkName: "modifypass" */
      ),
  },
  {
    path: '/',
    component: () =>
      import('@/views/frame.vue' /* webpackChunkName: "frame" */),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () =>
          import('@/views/index/index.vue' /* webpackChunkName: "index" */),
      },
      {
        path: '/announcement',
        name: 'announcement',
        component: () =>
          import(
            '@/views/announcement/announcement.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/announcement-info',
        name: 'announcement-info',
        component: () =>
          import(
            '@/views/announcement/announcement-info.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/download',
        name: 'download',
        component: () =>
          import(
            '@/views/download/download.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/download-info',
        name: 'download-info',
        component: () =>
          import(
            '@/views/download/download-info.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/notice',
        name: 'notice',
        component: () =>
          import('@/views/notice/notice.vue' /* webpackChunkName: "index" */),
      },
      {
        path: '/notice-info',
        name: 'notice-info',
        component: () =>
          import(
            '@/views/notice/notice-info.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/policyDocument',
        name: 'policyDocument',
        component: () =>
          import(
            '@/views/policyDocument/policyDocument.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/policyDocument-info',
        name: 'policyDocument-info',
        component: () =>
          import(
            '@/views/policyDocument/policyDocument-info.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/helpCenter',
        name: 'helpCenter',
        component: () =>
          import(
            '@/views/helpCenter/helpCenter.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/helpCenter-info',
        name: 'helpCenter-info',
        component: () =>
          import(
            '@/views/helpCenter/helpCenter-info.vue' /* webpackChunkName: "index" */
          ),
      },
      {
        path: '/czy/system/account',
        component: () =>
          import(
            '@/views/system/account/account.vue' /* webpackChunkName: "system-account" */
          ),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  next()
  // TODO 无登录 目前不做拦截
  // if (to.path == '/login' || to.path == '/register') {
  // 	next()
  // } else {
  // 	if (!localStorage.getItem('token')) {
  // 		next('/login')
  // 	} else {
  // 		base.setVuexResourceMenu(to.path);
  // 		next()
  // 	}
  // }
})

export default router
