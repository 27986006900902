<template>
    <div id="tree" class="tree"></div>
</template>
<script src="./../static/layui/layui.js"></script>
<script>
export default {
    props: {
        treeData: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {
        treeData: {
            deep: true,
            handler() {
                let that = this
                layui.use(['tree', 'util'], function () {
                    var tree = layui.tree,
                        layer = layui.layer,
                        util = layui.util
                    tree.render({
                        elem: '#tree',
                        data: that.treeData,
                        showLine: false,
                        click: function (obj) {
                            that.$emit('getNodeData', obj.data)
                        },
                    })
                })
            },
        },
    },
    methods: {},
    mounted() {
        let that = this
        layui.use(['tree', 'util'], function () {
            var tree = layui.tree,
                layer = layui.layer,
                util = layui.util
            tree.render({
                elem: '#tree',
                data: that.treeData,
                showLine: false,
                click: function (obj) {
                    that.$emit('getNodeData', obj.data)
                },
            })
        })
    },
    created() {},
}
</script>

<style scoped>
.tree >>> .layui-tree {
    line-height: auto;
}
.tree >>> .layui-tree-entry {
    height: auto;
}
.tree {
    max-height: 400px;
    overflow-y: scroll;
}
</style>
