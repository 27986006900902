import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid';

let formValidator = (rule, value, callback) => {
	// 公共校验方式重写  解决有值却提示不能为空问题
	if (!value) {
		callback(new Error('不能为空'));
	} else {
		callback();
	}
}

let util = {
    uuidv4: uuidv4,
    randomComponent(name, component) {
        const id = util.uuidv4().replace(/-/g, '')
        const comName = `${name}${id}`
        Vue.component(comName, component)
        return comName
    },
    customTableRender(h, comName, propsObj, bindFunObj) {
        let on = {};
        for (let [k, v] of Object.entries(bindFunObj)) {
            on[k] = v;
        }
        let props = {}
        for (let [k, v] of Object.entries(propsObj)) {
            props[k] = v;
        }

        return h(comName, {
            props,
            on,
        });
    },
    async timeDelay(time) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, time)
        })
    },
    async waitStatus(fun) {
        /* eslint-disable */
        while (true) {
            if (fun()) {
                return
            }
            util.timeDelay(0.1 * 1000)
        }
    },
    form: {
        validate: async (name, _this)=> {
            return await _this.$refs[name].validate((valid) => {
                return new Promise((resolve) => {
                    resolve(valid)
                  })
            })
        },
		inputRule: { required: true, validator: formValidator, trigger: 'blur' },
        // inputRule: { required: true, message: '不能为空', trigger: 'blur' },
        positiveNumber: { type: 'number', message: '请输入大于零的数字', transform(value) { return Number(value) }, min: 0 },
        idCard: { required: true, pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/, message: '请输入正确的身份证号', trigger: 'blur' },
        phone: { required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' },
        tel: { required: true, pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/, message: '请输入正确的手机号或座机号', trigger: 'blur' },
        creditCode: { required: true, pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, message: '请输入正确的统一社会信用代码', trigger: 'blur' },
        postCode: { required: true, pattern: /^[0-8][0-7]\d{4}$/, message: '请输入正确的邮政编码', trigger: 'blur' },
    },
    dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }
}
export default util
