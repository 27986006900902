/*
 * aioxs 接口请求函数
 */
import {fetch} from '../fetch'; // 引入fecth.js
import api from '../url'; // 引入url.js
// import qs from 'qs'; // 引入qs

/*
 * /views/base.js
 * *********************************************************************************************************************
 */
// 获取资源信息（所有、当前用户）
export function resourceList(data) {
    return fetch({
		url: api.resourceList,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 校验人员账户名称 是否已存在
export function accountCheckUniqueAccount(params) {
	return fetch({
		url: api.accountCheckUniqueAccount,
		method: 'get',
		params: params
	});
}

// 获取基础信息（系统类型、主体类型）
export function getSystemBaseCode(params, code) {
	return fetch({
		url: `${api.systemBaseCode}/${code}`,
		method: 'get',
		params: params
	});
}

// 区划对应的 系统/主体map
export function listTenantEntityTypeByTenantCode(params) {
	return fetch({
		url: api.listTenantEntityTypeByTenantCode,
		method: 'get',
		params: params
	});
}

// 校验用户名/统一社会信用代码是否注册
export function checkNameOrCreditCodeExist(data) {
    return fetch({
		url: api.checkNameOrCreditCodeExist,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 获取系统（行业分类/）树
export function systemTree(params) {
	return fetch({
		url: api.systemTree,
		method: 'get',
		params: params
	});
}


