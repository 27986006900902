<template>
    <div>
        <ul id="areaTree" class="ztree"></ul>
    </div>
</template>
<script>
import '@ztree/ztree_v3/js/jquery.ztree.core.min.js'
import '@ztree/ztree_v3/js/jquery.ztree.excheck.min.js'
import '@ztree/ztree_v3/css/zTreeStyle/zTreeStyle.css'
import '@ztree/ztree_v3/css/metroStyle/metroStyle.css'

export default {
    name: 'AreaTree',
    props: {
        showCheck: {
            type: Boolean,
            default: false,
        },
        zNodes: {
            type: Array,
            default: () => {
                return []
            },
        },
        onClick: {
            type: Function,
        },
    },
    components: {},
    data() {
        return {}
    },
    computed: {
        setting() {
            return {
                check: {
                    enable: this.showCheck,
                    nocheckInherit: false,
                    chkboxType: { Y: 'ps', N: 'ps' },
                    radioType: 'level',
                },
                data: {
                    simpleData: {
                        enable: true,
                    },
                },
                callback: {
                    onClick: this.onClick,
                },
            }
        },
    },
    watch: {
        zNodes: {
            immediate: true,
            handler() {
                this.refreshTree()
            },
        },
    },
    methods: {
        getZTreeObj() {
            return jquery.fn.zTree.getZTreeObj('areaTree')
        },
        refreshTree: function () {
            jquery.fn.zTree.init(jquery('#areaTree'), this.setting, this.zNodes).expandAll(true)
        },
    },
    mounted() {},
}
</script>

<style scoped>
/*@import '../../plugins/ztree/css/zTreeStyle.css'*/
</style>
