/*
 * aioxs 封接口地址
 * rest(表现层状态转化)url 面向资源
 */
export default {
  // 接口代理配置 见vue.config.js
  // 配置模块顺序为方便查看 尽可能对应views目录顺序

  /* 公共 模块 /api/base.js */
  resourceList: '/czy-uaa/resource/list', // 获取资源信息（所有、当前用户）
  accountCheckUniqueAccount: '/czy-uaa/account/checkUniqueAccount', // 校验人员账户名称 是否已存在
  systemBaseCode: '/czy-system/system/baseCode', // 获取基础信息（系统类型、主体类型）
  listTenantEntityTypeByTenantCode: '/czy-uaa/tenantEntity/listTenantEntityTypeByTenantCode', // 区划对应的 系统/主体map
  checkNameOrCreditCodeExist: 'czy-uaa/entity/checkNameOrCreditCodeExist', // 校验用户名/统一社会信用代码是否注册
  systemTree: '/czy-system/system/tree', // 获取系统（行业分类/）树

  /* 顶部公共 模块 /api/topHeader.js */
  removeToken: '/czy-uaa/oauth/remove/token', // 退出登录

  /* 登录/注册 模块 /api/main.js */
  getBusinessPlatforms: '/czy-system/platform/getBusinessPlatforms', // 获取主体类型Map-所有
  getTenantList: '/czy-uaa/tenant/getTenantList', // 获取所有区划
  oauthToken: '/czy-uaa/oauth/token', // 登录
  oauthLoginCode: '/czy-uaa/oauth/loginCode', // 登录
  RegisterAddUser: '/czy-uaa/account/fgjAddUser', // 注册-招商代理注册
  CheckRegisterPhone: '/czy-uaa/account/checkPhone', // 注册-校验手机号
  checkRegisterCreditCodeExist: '/czy-uaa/entity/checkNameOrCreditCodeExist', // 注册-企业信用代码
  RegisterSendCode: '/czy-uaa/account/sendCode', // 获取验证码

  /* 系统管理 模块 /api/system.js */
  accountList: '/czy-uaa/account/list', // 账户列表
  accountRoles: '/czy-uaa/account/roles', // 账户角色
  accountRelatedRoles: '/czy-uaa/account/relatedRoles', // 确认分配角色
  accountDel: '/czy-uaa/account/del', // 账户删除
  accountReset: '/czy-uaa/account/reset', // 账户重设密码
  forgetPawwsordReset: '/czy-uaa/account/forgetPawwsord/reset', // 账户忘记密码
  accountEnable: '/czy-uaa/account/enable', // 账户启用/禁用
  accountAdd: '/czy-uaa/account/add', // 账户新增
  accountUpdate: '/czy-uaa/account/update', // 账户修改

  roleList: '/czy-uaa/role/list', // 获取所有角色列表
  findResourceByRoleCode: '/czy-uaa/resource/findResourceByRoleCode', // 获取角色资源（权限）
  roleRelatedResource: '/czy-uaa/role/relatedResource', // 分配角色资源（权限）
  roleDel: '/czy-uaa/role/del', // 角色删除
  roleAdd: '/czy-uaa/role/add', // 角色新增
  roleUpdate: '/czy-uaa/role/update', // 角色修改

  elaSearch: '/czy-sms/ela/search', // 短信日志列表

  /* FormData /api/form.js */
  listToBeOpenedAll: '/smzj/openBiding/listToBeOpenedAll', // 查询开标信息

  /* 首页 /api/home.js */
  GetProjectNoticeLatest: '/fgj-promanage/projectNoticeInfo/projectNoticeLatest', // 首页-最新公告
  GetImportantNotice: '/fgj-promanage/notice/importantNotice', // 首页-重要通知

  GetProjectNoticePage: '/fgj-promanage/projectNoticeInfo/projectNoticePage', // 首页-项目公告列表
  GetProjectNoticeDetail: '/fgj-promanage/projectNoticeInfo/projectNoticeDetails', // 首页-项目公告详情

  GetPotalNoticePage: '/fgj-promanage/notice/noticePage', // 查询公告通知
  GetPotalNoticeDetail: '/fgj-promanage/notice/noticeDetails', // 查询公告通知详情
};

// 也可以像下面这样，区分环境或者区分服务器等等
// let service = 'dev';
// // let service = 'prod';
// let api = '';
// if (service === 'dev') {
//   /**dev开发**/
//   api = '/stomatology/patient';
// } else if (service === 'prod') {
//   /**prod部署**/
//   api = '/proxy/client';
// }
//
// export default {
//	// 声明api url
// }
