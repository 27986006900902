import CzyIcon from './iconfont/czy-icon.vue'

const components = {
  'c-icon': CzyIcon,
}

export default {
  install(Vue) {
    for (let k in components) {
      Vue.component(k, components[k])
    }
  },
}
