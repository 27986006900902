/*
 * axios 自定义配置  根据项目需求扩展
 */
import axios from 'axios'; // 引入 axios

export function fetch(options) {
    return new Promise((resolve, reject) => {
		// console.log("========> options")
		// console.log(options)
		
		// header设置
		let headers = {
			// 内容类型 设置为 序列化后的JSON字符串 需要其他类型直接在对应的api方法中设置即可 会在options中展现
			'Content-Type' : 'application/json', // 告诉服务端消息主体是序列化后的 JSON 字符串
            // 'Access-Control-Allow-Origin': window.location.hostname
		}
		// token 相关
		let noTokenApiArr = [
			'/czy-uaa/tenant/getTenantList',
			'/czy-system/system/baseCode/roleGroupCode',
			'/czy-system/system/baseCode/systemRootCode',
			'/czy-uaa/tenantEntity/listTenantEntityTypeByTenantCode',
			'/czy-system/platform/getBusinessPlatforms',
			'/czy-uaa/account/fgjAddUser',
			'/czy-uaa/account/forgetPawwsord/reset',
			'/czy-uaa/account/sendCode',
			'/czy-uaa/account/checkPhone',
			'/czy-uaa/entity/checkNameOrCreditCodeExist',
			'/czy-uaa/account/fgjAddUser',
			'/czy-uaa/account/fgjAddUser',
			'/czy-uaa/account/fgjAddUser',

			// 首页过滤token校验接口
			'/fgj-promanage/projectNoticeInfo/projectNoticeLatest',
			'/fgj-promanage/notice/importantNotice',
			'/fgj-promanage/projectNoticeInfo/projectNoticePage',
			'/fgj-promanage/projectNoticeInfo/projectNoticeDetails',
			'/fgj-promanage/notice/noticePage',
			'/fgj-promanage/notice/noticeDetails',
		]
		let token = localStorage.getItem('token')
		// 有token 且 请求地址不在不需token内
		if (token && noTokenApiArr.indexOf(options.url) < 0) {
			headers['Authorization'] = 'Bearer ' + token
		}
		
		// instance 创建一个 axios 实例，可以自定义配置，可在 axios 文档中查看详情
        const instance = axios.create({
            // 所有的请求都会带上这些配置，比如全局都要用的身份信息等
            headers: headers,
            timeout: 30 * 1000 //30秒超时
        });
		
        instance(options)
            .then(response => {
                // then 请求成功之后的操作
				// console.log(response)
				
				$this.$loading.hide() // loading 目前分 table.loading 和 frame.vue中定义的$loading TODO 应该改为具体调用逻辑中回调关闭
				
				let responseData = response.data.body.data; // 默认返回请求成功后的body.data数据
				
				if (response.status && response.status == 200) {
					if(!response.data.head.success) {
						
						if([401, 453].includes(response.data.head.code)) {
							if(!['/', '/login'].includes($this.$route.path)){
								let nextHref = encodeURIComponent($this.$route.path)
								$this.$router.push({
									path: `/login?next=${nextHref}`,
								})
							}
						} else {
							$this.$Message.warning(response.data.head.msg)
						}
						
					} else if(!response.data.body.success) {
						// 返回完整body数据
						responseData = response.data.body
						
						if (response.data.body.msg) {
							$this.$Message.error(response.data.body.msg);
						} else {
							$this.$Message.warning('接口返回出错')
						}
						reject(responseData)
						// return // TODO 此处return会导致table加载无法关闭
					}
					
					resolve(responseData) // 把请求到的数据发到引用请求的地方
					
				} else {
					$this.$Message.warning('接口返回出错')
				}
            })
            .catch(error => {
				// 请求失败之后的操作
				console.log('异常信息：' + error);
				// console.log(error.response);
				
				$this.$loading.hide(); // TODO 应该改为具体调用逻辑中回调关闭
				// 把错误信息发到引用请求的地方 特殊请求错误 应该自己写catch 接收这里的信息 进行处理
                reject(error);
				
				// 公共错误处理逻辑
				if(!error.response) {
					$this.$Message.error('系统正忙,请稍后再试!')
				} else if (!error.response.data.head) {
					$this.$Message.error(error.response.error || error.response.data.msg || `异常信息${error}`)
				} else if (error.response.data.head.msg) {
					$this.$Message.error(error.response.data.head.msg)
					if (error.response.status === 405) {
						if(!['/', '/login'].includes($this.$route.path)){
							let nextHref = encodeURIComponent($this.$route.path)
							$this.$router.push({
								path: `/login?next=${nextHref}`
							})
						}
					}
				} else {
					$this.$Message.error('系统正忙,请稍后再试!');
				}
            });
    });
}
