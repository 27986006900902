<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  provide() {
    // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    }
  },
  components: {},
  data() {
    return {
      isRouterAlive: true, // 控制视图是否显示的变量
    }
  },
  computed: {},
  watch: {},
  methods: {
    reload() {
      this.isRouterAlive = false // 先关闭
      this.$nextTick(() => {
        this.isRouterAlive = true // 再打开
      })
    },
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>
