import 'babel-polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// view-design (iView)
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import './theme/index.less'

// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

// bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap/dist/css/bootstrap.css'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// 公共
import '@/assets/css/global.css'

import '@/plugin/window.js'
import '@/plugin/base.js'
import '@/plugin/project.js'

// jQuery
import $ from 'jquery'
Vue.prototype.$ = $

// Base function
import base from '@/views/base.js'
Vue.prototype.$b = base

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

// UTable
import { UTable, UTableColumn, UxGrid, UxTableColumn } from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css' // 引入样式

// echarts
import * as echarts from 'echarts'
import '@/assets/js/walden.js'
Vue.prototype.$echarts = echarts

import Components from '@/components'
Vue.use(Components)

Vue.use(ViewUI)

Vue.use(VueAxios, axios)

// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueAwesomeSwiper)
AOS.init()

Vue.use(UTable)
Vue.use(UTableColumn)
Vue.use(UxGrid)
Vue.use(UxTableColumn)

Vue.config.productionTip = false

if (!('classList' in document.documentElement)) {
  Object.defineProperty(HTMLElement.prototype, 'classList', {
    get: function() {
      var self = this
      function update(fn) {
        return function(value) {
          var classes = self.className.split(/\s+/g),
            index = classes.indexOf(value)

          fn(classes, index, value)
          self.className = classes.join(' ')
        }
      }
      return {
        remove: update(function(classes, index) {
          if (~index) classes.splice(index, 1)
        }),
        add: update(function(classes, index, value) {
          if (~index) classes.splice(index, 0, value)
        }),
      }
    },
  })
}

Vue.directive('title', {
  inserted: function(e) {
    document.title = e.dataset.title
  },
})

let app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

window.$this = app
