import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        resource: {
            treeList: [],
        },
        menu: {
            menuList: [
                /* 示例 start */
                {
                    name: '系统管理',
                    route: null,
                    icon: 'md-settings',
                    open: false,
                    child: [
                        {
                            name: '账户管理',
                            route: '/czy/system/account',
                            icon: null,
                            open: false,
                            child: []
                        }
                    ]
                },
                /* 示例 end */
            ],
            routeList: [],
            buttonAuthList: [],
        },
        leftMenuNeedRefresh: false, // 左侧菜单是否需要刷新 解决点击菜单无法打开对应菜单首页问题
    },
    mutations: {
        $setState(state, data) {
            let target = state
            let keyList = data.key.split('.'),
                lastKey = keyList.pop()
            for (let key of keyList) {
                target = target[key]
            }
            Vue.set(target, lastKey, data.value)
        },
    },
    actions: {},
    modules: {}
})
