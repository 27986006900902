<template>
  <i :class="'iconfont ' + name"></i>
</template>

<script>
export default {
  name: 'CzyIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
